import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/index.js")),
  },
  {
    exact: true,
    path: "/withdrawal",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Withdrawal")),
  },
  {
    exact: true,
    path: "/income",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Income/index")),
  },
  {
    exact: true,
    path: "/manage-logos",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageLogos")),
  },
  {
    exact: true,
    path: "/support",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Support/index")),
  },
  {
    exact: true,
    path: "/userManagement",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/index")),
  },
  {
    exact: true,
    path: "/settings",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/adminSettings/index")),
  },
  {
    exact: true,
    path: "/manage-users",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageUsers")),
  },
  {
    exact: true,
    path: "/manage-games",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageGames/index")),
  },
  {
    exact: true,
    path: "/game-log",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageGames/gameLog")),
  },
  {
    exact: true,
    path: "/faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageSection/index")),
  },
  {
    exact: true,
    path: "/kyc",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Kyc/index")),
  },
  {
    exact: true,
    path: "/contactUs",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageSection/contactUs")),
  },
  {
    exact: true,
    path: "/feedback",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ManageSection/feedback")),
  },
  {
    exact: true,
    path: "/network",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Network/index")),
  },
  {
    exact: true,
    path: "/deposit",
    // guard: true,
    layout: DashboardLayout,  
    component: lazy(() => import("src/views/pages/Deposit/index")),
  },
 
  {
    exact: true,
    path: "/financial",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Financial/index")),
  },
  {
    exact: true,
    path: "/page-edit",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/pageEdit")),
  },
  {
    exact: true,
    path: "/edit-book-page",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/EditBookPage")),
  },
  {
    exact: true,
    path: "/page-view",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/pageView")),
  },
  {
    exact: true,
    path: "/view-image-data-record",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ImageDataRecord/view")),
  },

  {
    exact: true,
    path: "/coloring-book-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/index")),
  },
  {
    exact: true,
    path: "/edit-new-book",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/Edit")),
  },
  {
    exact: true,
    path: "/add-new-book",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/add.js")),
  },
  {
    exact: true,
    path: "/edit-model3D",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/editmodel3d.js")),
  },
  {
    exact: true,
    path: "/static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/index")),
  },
  {
    exact: true,
    path: "/view-static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/viewStatic")),
  },
  {
    exact: true,
    path: "/edit-static-content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContentManagement/editStatic")),
  },
  {
    exact: true,
    path: "/assets-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/index")),
  },
  {
    exact: true,
    path: "/view-assets",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/ViewAssets")),
  },
  {
    exact: true,
    path: "/view-withdraw",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/viewWithdraw.js")),
  },
  {
    exact: true,
    path: "/view-transfer",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/viewTransfer.js")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/AcActivation",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AcActivation/index")),
  },
  // {
  //   exact: true,
  //   path: "/view-user-details",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("@/views/pages/TransactionHistory/viewUserDetails.js")),
  // },
  {
    exact: true,
    path: "/upload-assets",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ColoringBookManagement/upload")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
